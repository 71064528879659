import { env } from '@/libs/env'

export default {
  Document: 'Document',
  // ANCHOR: danh sách hãng xuất 1G
  airlines1gCanPay: {
    title: 'Airlines 1G can pay',
    columns: {
      no: 'No',
      airline: 'Airline',
      adt: 'Adult',
      chd: 'Child',
      inf: 'Infant',
      void: 'Void',
      note: 'Note',
    },
  },

  // ANCHOR: Công văn tài liệu
  folderList: 'Folder list',
  createFolder: 'Create folder',
  renameFolder: 'Rename folder',
  searchNameFolder: 'Search folder name',
  searchNameDoc: 'Search document name',
  folder: 'folder',
  deleteFolder: 'Delete folder',
  folderName: 'Folder name',
  order: 'Order',
  message: {
    fetch: {
      error: 'Error fetching data',
    },
    add: {
      folderSuccess: 'Create folder successfully',
      folderError: 'Error create folder',
      documentSuccess: 'Create document successfully',
      documentError: 'Error create document',
    },
    delete: {
      folderSuccess: 'Delete folder successfully',
      folderError: 'Error delete folder',
      documentSuccess: 'Delete document successfully',
      documentError: 'Error delete document',
    },
    update: {
      folderSuccess: 'Update folder successfully',
      folderError: 'Error update folder',
      documentSuccess: 'Update document successfully',
      documentError: 'Error update document',
    },
    pinSuccess: 'Pin document successfully',
    pinError: 'Error pin document',
    unpinSuccess: 'Unpin document successfully',
    unpinError: 'Error unpin document',
  },
  columns: {
    folders: {
      Folder: 'Folder',
      'Created At': 'Created At',
      'Updated At': 'Updated At',
      Action: 'Action',
    },
    docs: {
      Name: 'Document',
      File: 'File',
      isShow: `${env.mamaShortName} 's Documentary`,
      isActive: 'Status',
      status: 'Status',
      'Created At': 'Created At',
      'Updated At': 'Updated At',
      Action: 'Action',
    },
  },
  file: 'File',
  createDoc: 'Create document',
  doc: 'document',
  infoDocs: 'Document infomation',
  docsName: 'Document name',
  ph: { // placeholder
    docsName: 'Enter document name',
    folder: 'Select folder',
  },
  Folder: 'Folder',
  docsStatus: 'Document status',
  isShowTitle: `${env.mamaShortName} 's Documentary`,
  isActiveTitle: 'Publish',
  isPinTitle: 'Pin',
  priorityPin: {
    title: 'Priority pin',
    HIGH: 'High priority',
    MEDIUM: 'Medium priority',
    LOW: 'Low priority',
    descHIGH: 'High priority: Notifications are displayed in red text at the top of the list.',
    descMEDIUM: '',
    descLOW: '',
  },
  forF3: 'Public for AG F3',
  forF2: 'Only publish for AG F2',
  published: 'Published',
  publish: 'Publish',
  draft: 'Draft',
  description: 'Description',
  docDetail: 'Document detail',
  docEdit: 'Edit document',
}
